
import { Component, Vue } from 'vue-property-decorator'
const BreadCrumb = () => import('../components/BreadCrumbs.vue')
@Component({
  components: { BreadCrumb }
})
export default class About extends Vue {
  images = [
    'https://soimagescdn.azureedge.net/fdp/aboutus/cpsia_custom.png',
    'https://soimagescdn.azureedge.net/fdp/aboutus/ul-greenguard.png',
    'https://soimagescdn.azureedge.net/fdp/aboutus/certipur-us_rgb_800x800.png',
    'https://soimagescdn.azureedge.net/fdp/aboutus/bifma-red.png'
  ]
}
